const Service200Suburbs = () => {
    return ( 
        <section data-bs-version="5.1" className="header1 cid-sFCAOqBTxa" id="header1-i">

    

    <div className="mbr-overlay" style={{opacity: '0.7', backgroundColor: "rgb(255, 255, 255)"}}></div>

    <div className="container">
        
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
                <h1 className="mbr-section-title mbr-fonts-style mb-3 display-2"><strong>Servicing More than 200 suburbs of Melbourne</strong></h1>
                <h2 className="mbr-section-subtitle mbr-fonts-style mb-3 display-2">
                        Free PickUp and Delivery at your door step</h2>
                <p className="mbr-text mbr-fonts-style display-7">Proudly servicing over 200 suburbs of Melbourne, our extensive reach ensures that businesses across the city can benefit from our exceptional commercial laundry services. From the heart of the CBD to the outer edges of south-east Melbourne, our dedicated team is committed to providing reliable and convenient laundry solutions to a wide range of businesses throughout over 200 suburbs.<br/></p>
                
            </div>
        </div>
    </div>
</section>
     );
}
 
export default Service200Suburbs;