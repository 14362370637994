/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSuburbs = /* GraphQL */ `
  query GetSuburbs($id: ID!) {
    getSuburbs(id: $id) {
      id
      name
      description1
      description2
      description3
      photo1
      photo2
      pagetitle
      metadescription
      createdAt
      updatedAt
    }
  }
`;
export const listSuburbs = /* GraphQL */ `
  query ListSuburbs(
    $filter: ModelSuburbsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuburbs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description1
        description2
        description3
        photo1
        photo2
        pagetitle
        metadescription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
