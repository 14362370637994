const MainpageHero = () => {
    return ( 
        <section data-bs-version="5.1" className="header3 cid-tGMQK9Lnsr mbr-fullscreen mbr-parallax-background" id="header3-6">

    

    
    <div className="mbr-overlay" style={{'opacity': '0.6', backgroundColor: 'rgb(255, 255, 255)'}}></div>

    <div className="align-center container">
        <div className="row justify-content-end">
            <div className="col-12 col-lg-7">
                <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>Commercial Laundry Services Melbourne</strong></h1>
                <h2 className="mbr-section-subtitle mbr-fonts-style mb-3 display-2">Efficient and Reliable Laundry Solutions for Businesses in Melbourne</h2>
                <p className="mbr-text mbr-fonts-style display-7">Commercial Laundry Services Melbourne offers top-notch laundry solutions tailored to the unique needs of businesses in the bustling city, ensuring impeccable cleanliness and efficiency. With our professional expertise and state-of-the-art equipment, Commercial Laundry Services Melbourne is the trusted partner for businesses seeking reliable and timely laundry services to maintain a pristine image and exceptional </p>
                <div className="mbr-section-btn mt-3"><a className="btn btn-success display-4" href="https://oakleighcoinlaundry.com.au/get-quotation">Get Customised Quotation</a></div>
            </div>
        </div>
    </div>
</section>
     );
}
 
export default MainpageHero;