const CommercialLaundryServicesBlock = () => {
    return ( 
        <section data-bs-version="5.1" className="features3 cid-tGMZbCg82u" id="features3-7">
    
    
    <div className="container">
        <div className="mbr-section-head">
            
            <h5 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">Efficient and Professional Commercial Laundry Services for Businesses and Organizations</h5>
        </div>
        <div className="row mt-4">
            <div className="item features-image сol-12 col-md-6 col-lg-4">
                <div className="item-wrapper">
                    <div className="item-img">
                        <img src="assets/images/mbr-816x522.webp" alt=""/>
                    </div>
                    <div className="item-content">
                        <h5 className="item-title mbr-fonts-style display-7"><strong>Restaurants and Clubs</strong></h5>
                        
                        <p className="mbr-text mbr-fonts-style mt-3 display-7">From hotels and clubs to various commercial businesses, we cater to the laundry needs of diverse industries with precision and expertise.</p>
                    </div>
                    <div className="mbr-section-btn item-footer mt-2"><a href="https://oakleighcoinlaundry.com.au/business-laundry-for-restaurant-service" className="btn btn-primary item-btn display-7" target="_blank">Learn More
                            &gt;</a></div>
                </div>
            </div>
            <div className="item features-image сol-12 col-md-6 col-lg-4">
                <div className="item-wrapper">
                    <div className="item-img">
                        <img src="assets/images/mbr-816x544.webp" alt=""/>
                    </div>
                    <div className="item-content">
                        <h5 className="item-title mbr-fonts-style display-7"><strong>Hotels, Motels and BnB</strong></h5>
                        
                        <p className="mbr-text mbr-fonts-style mt-3 display-7">From luxurious hotels to cozy B&amp;Bs and motels, our comprehensive laundry services cater to the diverse needs of the hospitality industry.</p>
                    </div>
                    <div className="mbr-section-btn item-footer mt-2"><a href="https://oakleighcoinlaundry.com.au/business-laundry-for-hotel-service" className="btn btn-primary item-btn display-7" target="_blank">Learn More
                            &gt;</a></div>
                </div>
            </div>
            <div className="item features-image сol-12 col-md-6 col-lg-4">
                <div className="item-wrapper">
                    <div className="item-img">
                        <img src="assets/images/mbr-816x534.webp" alt=""/>
                    </div>
                    <div className="item-content">
                        <h5 className="item-title mbr-fonts-style display-7"><strong>Hospitals and Care Facilities</strong></h5>
                        
                        <p className="mbr-text mbr-fonts-style mt-3 display-7">Ensuring cleanliness and hygiene are our top priorities as we provide reliable laundry services tailored to the unique requirements of hospitals and care facilities<br/></p>
                    </div>
                    <div className="mbr-section-btn item-footer mt-2"><a href="https://oakleighcoinlaundry.com.au/business-laundry-for-hospitals-and-care-facilities-service" className="btn btn-primary item-btn display-7" target="_blank">Learn More
                            &gt;</a></div>
                </div>
            </div>
        </div>
    </div>
</section>
     );
}
 
export default CommercialLaundryServicesBlock;