import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { getSuburbs } from "../../graphql/queries";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SuburbPageHero = (props) => {

const [suburbName, setSuburbName] = useState("");
const [description1, setDescription1] = useState("");
const [description2, setDescription2] = useState("");
const [description3, setDescription3] = useState("");
const [photo1, setPhoto1] = useState("");
const [photo2, setPhoto2] = useState("");

useEffect(() => {
    
    API.graphql(graphqlOperation(getSuburbs, {id: props.suburbId})).then((result) => {
        console.log(result);
        setSuburbName(result.data.getSuburbs.name);
        setDescription1(result.data.getSuburbs.description1);
        setDescription2(result.data.getSuburbs.description2);
        setDescription3(result.data.getSuburbs.description3);

        var tempPhoto1 = result.data.getSuburbs.photo1;
        if (tempPhoto1.length < 30) {
            setPhoto1("https://s3.ap-southeast-2.amazonaws.com/oakleighcoinlaundry.com.au/suburbsphotos/"+result.data.getSuburbs.photo1);
        } else {
            setPhoto1(result.data.getSuburbs.photo1);
        }
        
        // setPhoto2(result.data.getSuburbs.photo2);
    }).catch((error) => {
        console.log(error);
    });
}
, [props.suburbId]);

    return ( 
    <HelmetProvider>
    <div >
    <Helmet>
                <title>Commercial Laundry Services in {suburbName}</title>
                <meta name="description" content={"Reliable commercial laundry services in "+suburbName+". From hotels to restaurants, gyms to spas, we handle large volumes of laundry with efficiency and attention to detail. Our professional team offers top-notch solutions for businesses in the area. Experience exceptional service and prompt turnaround times. Contact us today for all your commercial laundry needs in "+suburbName+"."} />
    </Helmet>
        <section data-bs-version="5.1" className="content1 cid-tGUFr1C8Qy mbr-parallax-background" id="content1-d">
    

    
    <div className="mbr-overlay" style={{opacity: '0.7', backgroundColor: 'rgb(255, 255, 255)'}}>
    </div>

    <div>
        <div className="row">
            <div className="col-12">
                <div className="title-wrapper">
                    <h1 className="mbr-section-title mbr-fonts-style display-1">
                        <strong>Commercial Laundry Service in {suburbName}</strong></h1>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" className="article2 cid-tGUFnKQc53" id="article02-c">

    

    
    

    <div className="container" >
        <div className="row justify-content-center">
            <div className="col-12 pb-4">
            
            <h1 className="mbr-section-title mbr-fonts-style mb-5 display-3"><strong>{suburbName}</strong></h1>
            </div>
            <p className="mbr-text mbr-fonts-style display-7">
                        {description1}</p>
            <div className="col-12 mb-4 col-md-12 col-lg-6 image-wrapper">
                <img className="w-100" src={photo1} alt=""/>
            </div>
            <div className="col-12 col-lg col-md-12">
                <div className="text-wrapper align-left">
                    
                    <p className="mbr-text mbr-fonts-style display-7">
                        {description2}</p>
                        <p className="mbr-text mbr-fonts-style display-7">
                        {description3}</p>
                    
                </div>
            </div>
              
            
        </div>
    </div>
</section>
    </div>
    </HelmetProvider> );
}
 
export default SuburbPageHero;