const SuburbList = () => {
    return ( 
        <section data-bs-version="5.1" className="features17 cid-tGMAGMn7FR" id="features18-4">

    

    
    
    <div className="container">
        <div className="mbr-section-head">
            <div className="col-12">
                <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                    <strong>List of Melbourne Suburbs that we serve</strong></h4>
                
            </div>
        </div>
        <div className="row mt-4">
            <div className="col-12 col-md-6 col-lg-3">
                <div className="card-wrapper mb-4">
                    <div className="card-box align-left">
                        
                        <p className="mbr-text mbr-fonts-style mb-3 display-7">
                       
                            <a href="/laundry-service-ashburton">Ashburton</a><br/>
                            <a href="/laundry-service-ashburton-north">Ashburton North</a><br/>
                            <a href="/laundry-service-ashwood">Ashwood</a><br/>
                            <a href="/laundry-service-balwyn">Balwyn</a><br/>
                            <a href="/laundry-service-balwyn-north">Balwyn North</a><br/>
                            <a href="/laundry-service-bayswater">Bayswater</a><br/>
                            <a href="/laundry-service-beaumaris">Beaumaris</a><br/>
                            <a href="/laundry-service-bentleigh">Bentleigh</a><br/>
                            <a href="/laundry-service-bentleigh-east">Bentleigh East</a><br/>
                            <a href="/laundry-service-blackburn">Blackburn</a><br/>
                            <a href="/laundry-service-blackburn-north">Blackburn North</a><br/>
                            <a href="/laundry-service-blackburn-south">Blackburn South</a><br/>
                            <a href="/laundry-service-black-rock">Black Rock</a><br/>
                            <a href="/laundry-service-boxhill">Box Hill</a><br/>
                            <a href="/laundry-service-boxhill-central">Box Hill Central</a><br/>
                            <a href="/laundry-service-boxhill-north">Box Hill North</a><br/>
                            <a href="/laundry-service-boxhill-south">Box Hill South</a><br/>
                            <a href="/laundry-service-brighton">Brighton</a><br/>
                            <a href="/laundry-service-brighton-east">Brighton East</a><br/>
                            <a href="/laundry-service-brighton-north">Brighton North</a><br/>
                            <a href="/laundry-service-burnley">Burnley</a><br/>
                            <a href="/laundry-service-burwood">Burwood</a><br/>
                            <a href="/laundry-service-camberwell">Camberwell</a><br/>
                            <a href="/laundry-service-camberwell-east">Camberwell East</a><br/>
                            <a href="/laundry-service-camberwell-junction">Camberwell Junction</a><br/>
                            <a href="/laundry-service-camberwell-north">Camberwell North</a><br/>
                            <a href="/laundry-service-camberwell-south">Camberwell South</a><br/>
                            <a href="/laundry-service-camberwell-west">Camberwell West</a><br/>
                            <a href="/laundry-service-carnegie">Carnegie</a><br/>
                            <a href="/laundry-service-carnegie-north">Carnegie North</a><br/>
                            <a href="/laundry-service-caulfield">Caulfield</a><br/>
                            <a href="/laundry-service-caulfield-east">Caulfield East</a><br/>
                            <a href="/laundry-service-caulfield-junction">Caulfield Junction</a><br/>
                            <a href="/laundry-service-caulfield-north">Caulfield North</a><br/>
                            <a href="/laundry-service-caulfield-south">Caulfield South</a><br/>
                            <a href="/laundry-service-caulfield-west">Caulfield West</a><br/>
                            <a href="/laundry-service-chadstone">Chadstone</a><br/>
                            <a href="/laundry-service-chelsea">Chelsea</a><br/>
                            <a href="/laundry-service-chelsea-heights">Chelsea Heights</a><br/>
                            <a href="/laundry-service-cheltenham">Cheltenham</a><br/>
                            <a href="/laundry-service-cheltenham-east">Cheltenham East</a><br/>
                            <a href="/laundry-service-cheltenham-north">Cheltenham North</a><br/>
                            <a href="/laundry-service-clarinda">Clarinda</a><br/>
                            <a href="/laundry-service-clayton">Clayton</a><br/>
                            <a href="/laundry-service-clayton-north">Clayton North</a><br/>
                            <a href="/laundry-service-clayton-south">Clayton South</a><br/>
                            <a href="/laundry-service-dandanong">Dandenong</a><br/>
                            <a href="/laundry-service-dandenong-north">Dandenong North</a><br/>
                            <a href="/laundry-service-dingleyvillage">Dingley Village</a><br/>
                            <a href="/laundry-service-doncaster">Doncaster</a><br/>

                        East Brighton<br/>Edithvale<br/>Elsternwick<br/>Elsternwick North<br/>Elwood<br/>Forest Hill<br/>Gardenvale<br/>Glen Huntly<br/>Glen Iris<br/>Glen Waverley<br/>Hampton<br/>Hampton East<br/>Hawthorn<br/>Highett<br/>Hughesdale<br/>Huntingdale</p><div className="link-wrap">
                                
                            </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
                <div className="card-wrapper mb-4">
                    <div className="card-box align-left">
                        
                        <p className="mbr-text mbr-fonts-style mb-3 display-7">Kew<br/>Kew East<br/>Keysborough<br/>Malvern<br/>Malvern East<br/>Mentone<br/>Moorabbin<br/>Mordialloc<br/>Mount Waverley<br/>Mulgrave<br/>Murrumbeena<br/>Noble Park<br/>Notting Hill<br/>Oakleigh<br/>Oakleigh East<br/>Oakleigh South<br/>Ormond<br/>Parkdale<br/>Prahran<br/>Richmond<br/>Sandringham<br/>South Yarra<br/>Springvale<br/>St Kilda<br/>Surrey Hills<br/>Templestowe<br/>Toorak<br/>Upper Ferntree Gully<br/>Vermont<br/>Vermont South<br/>Waverley Gardens<br/>Wantirna<br/>Wheelers Hill<br/>Wheelers Hill South<br/>Wonga Park<br/>Aberfeldie<br/>Airport West<br/>Alphington<br/>Armadale North<br/>Ascot Vale<br/>Avondale Heights<br/>Balaclava<br/>Bellfield<br/>Bentleigh West<br/>Box Hill Gardens<br/>Box Hill North East<br/>Box Hill South East<br/>Braeside<br/>Braybrook<br/>Braybrook North<br/>Brighton Beach<br/>Brunswick<br/>Brunswick East<br/>Brunswick West<br/>Burwood East<br/>Canterbury<br/>Carlton<br/>Carlton North<br/>Caulfield North East<br/>Chelsea Beach<br/>Chelsea South<br/>Coburg<br/>Coburg North<br/>Collingwood<br/>Deepdene<br/>Docklands</p><div className="link-wrap">
                                
                            </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
                <div className="card-wrapper mb-4">
                    <div className="card-box align-left">
                        
                        <p className="mbr-text mbr-fonts-style mb-3 display-7">Doncaster East<br/>Eaglemont<br/>East Melbourne<br/>Eltham<br/>Eltham North<br/>Essendon<br/>Essendon North<br/>Fairfield<br/>Fawkner<br/>Fitzroy<br/>Fitzroy North<br/>Flemington<br/>Footscray<br/>Glen Iris North<br/>Glenroy<br/>Gowanbrae<br/>Hawthorn East<br/>Heidelberg<br/>Heidelberg Heights<br/>Heidelberg West<br/>Ivanhoe<br/>Ivanhoe East<br/>Keilor East<br/>Kensington<br/>Kilsyth<br/>Kingsbury<br/>Kingsville<br/>Kooyong<br/>Laverton<br/>Lower Templestowe<br/>Macleod<br/>Maidstone<br/>Maribyrnong<br/>Melbourne CBD<br/>Middle Park<br/>Mitcham<br/>Mont Albert<br/>Mont Albert North<br/>Moonee Ponds<br/>Newport<br/>Niddrie<br/>North Melbourne<br/>Northcote<br/>Oak Park<br/>Parkville<br/>Pascoe Vale<br/>Pascoe Vale South<br/>Port Melbourne<br/>Preston<br/>Princes Hill<br/>Reservoir<br/>Ringwood<br/>Ringwood East<br/>Ringwood North<br/>Rosanna<br/>Seddon<br/>South Melbourne<br/>South Wharf<br/>Southbank<br/>Spotswood<br/>St Albans<br/>Strathmore<br/>Strathmore Heights<br/>Sunshine<br/>Sunshine North<br/>Sunshine West</p><div className="link-wrap">
                                
                            </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
                <div className="card-wrapper mb-4">
                    <div className="card-box align-left">
                        
                        <p className="mbr-text mbr-fonts-style mb-3 display-7">Surrey Hills South<br/>Templestowe Lower<br/>Thornbury<br/>Tottenham<br/>Travancore<br/>West Footscray<br/>West Melbourne<br/>Westgarth<br/>Williamstown<br/>Williamstown North<br/>Windsor<br/>Yallambie<br/>Yarraville<br/>Yarraville West<br/>Abbotsford<br/>Altona<br/>Altona Meadows<br/>Altona North<br/>Bayswater North<br/>Bonbeach<br/>Boronia<br/>Broadmeadows<br/>Brunswick South<br/>Carrum<br/>Chirnside Park<br/>Croydon<br/>Croydon Hills<br/>Croydon North<br/>Croydon South<br/>Dandenong South<br/>Deer Park<br/>Donvale<br/>East Bentleigh<br/>Emerald<br/>Endeavour Hills<br/>Ferntree Gully<br/>Fitzroy Crossing<br/>Flemington Racecourse<br/>Footscray West<br/>Glenferrie South<br/>Hampton North<br/>Heatherton<br/>Hillside<br/>Huntingdale Station<br/>Kealba<br/>Keilor<br/>Keilor Downs<br/>Keilor Park<br/>Kilsyth South<br/>Knoxfield<br/>Lalor<br/>Langwarrin<br/>Lilydale<br/>Lower Plenty<br/>Lynbrook<br/>Lyndhurst<br/>McCrae<br/>McKinnon<br/>Meadow Heights<br/>Melbourne Airport<br/>Mill Park<br/>Mitcham North<br/>Montmorency<br/>Moorabbin East<br/>Mooroolbark<br/>Noble Park North</p><div className="link-wrap">
                                
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
     );
}
 
export default SuburbList;