import { Helmet } from "react-helmet-async";
import BenefitsofUsingUs from "../Components/BenefitsofUsingUs";
import CommerLaundryMel from "../Components/CommercialLauandryMel";
import CommercialLaundryServicesBlock from "../Components/CommercialServicesBlock";
import ProcessBlock from "../Components/ProcessBlock";
import SuburbPageHero from "../Components/SuburbPages/suburbPageHero";
const SuburbPages = (props) => {
    return (      
        
<div>


<SuburbPageHero suburbId={props.suburbId} />
<CommercialLaundryServicesBlock />
<BenefitsofUsingUs />
<CommerLaundryMel />
<ProcessBlock />

</div>

     );
}
 
export default SuburbPages;