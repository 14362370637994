
import './App.css';
import Footer from './Components/Footer';
import SuburbList from './Components/ListofSuburbs/SuburbsList';
import NavBar from './Components/NavBar';
import HomePage from './Pages/HomePage';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SuburbPages from './Pages/SuburbPage';
import ServiceSuburbs from './Pages/ServiceSuburbs';
import { Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  return ( <div>
  <NavBar />
    <Router>
      <div>
        <Switch>
        <Route exact path="/laundry-service-ashburton">   <SuburbPages suburbId="ashburton" />  </Route>
        <Route exact path="/laundry-service-ashburton-north">   <SuburbPages suburbId="ashburton-north" />  </Route>
        <Route exact path="/laundry-service-ashwood">   <SuburbPages suburbId="ashwood" />  </Route>
        <Route exact path="/laundry-service-balwyn">   <SuburbPages suburbId="balwyn" />  </Route>
        <Route exact path="/laundry-service-balwyn-north">   <SuburbPages suburbId="balwyn-north" />  </Route>
        <Route exact path="/laundry-service-bayswater">   <SuburbPages suburbId="bayswater" />  </Route>
        <Route exact path="/laundry-service-beaumaris">   <SuburbPages suburbId="beaumaris" />  </Route>
        <Route exact path="/laundry-service-bentleigh">   <SuburbPages suburbId="bentleigh" />  </Route>
        <Route exact path="/laundry-service-bentleigh-east">   <SuburbPages suburbId="bentleigh-east" />  </Route>
        <Route exact path="/laundry-service-blackburn">   <SuburbPages suburbId="blackburn" />  </Route>
        <Route exact path="/laundry-service-blackburn-north">   <SuburbPages suburbId="blackburn-north" />  </Route>
        <Route exact path="/laundry-service-blackburn-south">   <SuburbPages suburbId="blackburn-south" />  </Route>
        <Route exact path="/laundry-service-black-rock">   <SuburbPages suburbId="black-rock" />  </Route>
        <Route exact path="/laundry-service-boxhill">   <SuburbPages suburbId="boxhill" />  </Route>
        <Route exact path="/laundry-service-boxhill-central">   <SuburbPages suburbId="boxhill-central" />  </Route>
        <Route exact path="/laundry-service-boxhill-north">   <SuburbPages suburbId="boxhill-north" />  </Route>
        <Route exact path="/laundry-service-boxhill-south">   <SuburbPages suburbId="boxhill-south" />  </Route>
        <Route exact path="/laundry-service-brighton"> <SuburbPages suburbId="brighton"/> </Route> 
        <Route exact path="/laundry-service-brighton-east"> <SuburbPages suburbId="brighton-east"/> </Route> 
        <Route exact path="/laundry-service-brighton-north"> <SuburbPages suburbId="brighton-north"/> </Route> 
        <Route exact path="/laundry-service-burnley"> <SuburbPages suburbId="burnley"/> </Route> 
        <Route exact path="/laundry-service-burwood"> <SuburbPages suburbId="burwood"/> </Route> 
        <Route exact path="/laundry-service-camberwell"> <SuburbPages suburbId="camberwell"/> </Route> 
        <Route exact path="/laundry-service-camberwell-east"> <SuburbPages suburbId="camberwell-east"/> </Route> 
        <Route exact path="/laundry-service-camberwell-junction"> <SuburbPages suburbId="camberwell-junction"/> </Route> 
        <Route exact path="/laundry-service-camberwell-north"> <SuburbPages suburbId="camberwell-north"/> </Route> 
        <Route exact path="/laundry-service-camberwell-south"> <SuburbPages suburbId="camberwell-south"/> </Route> 
        <Route exact path="/laundry-service-camberwell-west"> <SuburbPages suburbId="camberwell-wes"/> </Route> 
        <Route exact path="/laundry-service-carnegie"> <SuburbPages suburbId="carnegie"/> </Route> 
        <Route exact path="/laundry-service-carnegie-north"> <SuburbPages suburbId="carnegie-north"/> </Route> 
        <Route exact path="/laundry-service-caulfield"> <SuburbPages suburbId="caulfield"/> </Route> 
        <Route exact path="/laundry-service-caulfield-east"> <SuburbPages suburbId="caulfield-east"/> </Route> 
        <Route exact path="/laundry-service-caulfield-junction"> <SuburbPages suburbId="caulfield-junction"/> </Route> 
        <Route exact path="/laundry-service-caulfield-north"> <SuburbPages suburbId="caulfield-north"/> </Route> 
        <Route exact path="/laundry-service-caulfield-south"> <SuburbPages suburbId="caulfield-south"/> </Route> 
        <Route exact path="/laundry-service-caulfield-west"> <SuburbPages suburbId="caulfield-west"/> </Route> 
        <Route exact path="/laundry-service-chadstone"> <SuburbPages suburbId="chadstone"/> </Route> 
        <Route exact path="/laundry-service-chelsea"> <SuburbPages suburbId="chelsea"/> </Route> 
        <Route exact path="/laundry-service-chelsea-heights"> <SuburbPages suburbId="chelsea-heights"/> </Route> 
        <Route exact path="/laundry-service-cheltenham"> <SuburbPages suburbId="cheltenham"/> </Route> 
        <Route exact path="/laundry-service-cheltenham-east"> <SuburbPages suburbId="cheltenham-east"/> </Route> 
        <Route exact path="/laundry-service-cheltenham-north"> <SuburbPages suburbId="cheltenham-north"/> </Route> 
        <Route exact path="/laundry-service-clarinda"> <SuburbPages suburbId="clarinda"/> </Route> 
        <Route exact path="/laundry-service-clayton"> <SuburbPages suburbId="clayton"/> </Route> 
        <Route exact path="/laundry-service-clayton-north"> <SuburbPages suburbId="clayton-north"/> </Route> 
        <Route exact path="/laundry-service-clayton-south"> <SuburbPages suburbId="clayton-south"/> </Route> 
        <Route exact path="/laundry-service-dandanong"> <SuburbPages suburbId="dandanong"/> </Route> 
        <Route exact path="/laundry-service-dandenong-north"> <SuburbPages suburbId="dandenong-north"/> </Route> 
        <Route exact path="/laundry-service-dingleyvillage"> <SuburbPages suburbId="dingleyvillage"/> </Route> 
        <Route exact path="/laundry-service-doncaster"> <SuburbPages suburbId="doncaster"/> </Route> 
        
        
        <Route exact path="/laundry-service-suburbs">   <ServiceSuburbs />  </Route>
        <Route exact path="/home">   <HomePage/> </Route>
        <Route exact path="/*">   <HomePage/>   </Route>
    
        </Switch>
      </div>
    </Router>

  <SuburbList />
  <Footer />
  </div>
  );
}

export default App;
