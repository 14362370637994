import Service200Suburbs from "../Components/ComponentOne";
import SuburbList from "../Components/ListofSuburbs/SuburbsList";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ServiceSuburbs = () => {
    return ( 
        <HelmetProvider>
        
        <div>
        <Helmet>
                <title>Laundry services for your business in more that 200 suburbs of melbourne</title>
                <meta name="description" content="Unlock hassle-free laundry services for your business in over 200 suburbs of Melbourne. Our professional team specializes in catering to the unique needs of various industries, including hotels, restaurants, and more. With a focus on quality and efficiency, we handle your laundry requirements with utmost care and precision. Experience reliable and convenient laundry services for your business in Melbourne today. Contact us to discuss your specific needs and let us take care of your laundry so you can focus on what matters most." />
            </Helmet> 
        <Service200Suburbs />
        
        </div>
        </HelmetProvider>
     );
}
 
export default ServiceSuburbs;