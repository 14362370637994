const NavBar = () => {
    return ( 
       
       <section data-bs-version="5.1" className="menu menu3 cid-sFAA5oUu2Y" once="menu" id="menu3-1">
    
    <nav className="navbar navbar-dropdown navbar-expand-lg">
        <div className="container">
            <div className="navbar-brand">
            <span className="navbar-logo">
                    <a href="#"> 
                        <img src="assets/images/oakleigh-coin-laundry-logo-137x137.png" alt="" style={{height: '6.4rem'}}/>
                    </a>
                </span>
                
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                    <li className="nav-item"><a className="nav-link link text-primary display-7" href="/">Home&nbsp;</a></li>
                    <li className="nav-item"><a className="nav-link link text-primary display-7" href="/laundry-service-suburbs">Service Areas&nbsp;</a></li>
                    <li className="nav-item"><a className="nav-link link text-primary display-7" href="https://oakleighcoinlaundry.com.au/appointment">Book Appointment</a></li>
                    <li className="nav-item"><a className="nav-link link text-primary display-7" href="https://oakleighcoinlaundry.com.au/About">About</a></li></ul>
                
                <div className="navbar-buttons mbr-section-btn"><a className="btn btn-primary display-4" href="https://oakleighcoinlaundry.com.au/get-quotation">Get Quotation</a></div>
            </div>
        </div>
    </nav>
</section> 
     );
}
 
export default NavBar;