const Footer = () => {
    return ( 
        <section data-bs-version="5.1" className="footer3 cid-sFAOjz8nX7" once="footers" id="footer3-d">

    <div className="container">
        <div className="media-container-row align-center mbr-white">
            
            
            <div className="row row-copirayt">
                <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                    Website created by <a href="https://symly.com.au" className="text-warning">Symly.com.au</a></p>
            </div>
        </div>
    </div>
</section>
     );
}
 
export default Footer;