import BenefitsofUsingUs from "../Components/BenefitsofUsingUs";
import CommerLaundryMel from "../Components/CommercialLauandryMel";
import CommercialLaundryServicesBlock from "../Components/CommercialServicesBlock";
import Service200Suburbs from "../Components/ComponentOne";
import LocationSearch from "../Components/LocationSearch";
import MainpageHero from "../Components/MainPageHero";
import ProcessBlock from "../Components/ProcessBlock";
import SomeDescBlock from "../Components/SomeDescBlock";
import useDocumentTitle from "../CustomHooks.js/useDocumentTitle";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HomePage = () => {
    return ( 
        <HelmetProvider>
            <div>
            <Helmet>
                <title>Commercial Laundry Services Melbourne | Laundry Services Near Me</title>
                <meta name="description" content="Experience top-notch commercial laundry services in Melbourne with our expert team. Our laundry services are designed to cater to businesses of all sizes, whether you own a hotel, restaurant, or spa. With a focus on quality and efficiency, we handle large volumes of laundry with utmost care. Discover reliable and professional commercial laundry services near you. Contact us now to discuss your requirements and experience the difference with our exceptional services." />
            </Helmet>
        
        
            
            <MainpageHero />
            <Service200Suburbs />
            <LocationSearch />
            <ProcessBlock />
            <CommercialLaundryServicesBlock />
            <SomeDescBlock />
            <BenefitsofUsingUs />
            <CommerLaundryMel />
            </div>
        </HelmetProvider>
     );
}
 
export default HomePage;