const ProcessBlock = () => {
    return ( 
        <section data-bs-version="5.1" className="image1 cid-tELN0ZMuwl" id="image1-2">
    

    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-lg-5">
                <div className="image-wrapper">
                    <img src="assets/images/process.gif" alt=""/>
                    <p className="mbr-description mbr-fonts-style pt-2 align-center display-4">Laundry pick up and drop off process</p>
                </div>
            </div>
            <div className="col-12 col-lg">
                <div className="text-wrapper">
                    <h3 className="mbr-section-title mbr-fonts-style mb-3 display-5">
                        <strong>Quick Reliable Laundry Service In Melbourne</strong></h3>
                    <p className="mbr-text mbr-fonts-style display-7">Our laundry service process is designed to provide you with utmost convenience and efficiency. <br/><strong><br/></strong><br/><strong>Step-1:</strong> Booking online is quick and easy. Simply visit our website, select your desired slot and <a href="https://oakleighcoinlaundry.com.au/appointment" className="text-primary"><strong>book your appointment </strong></a>with just a few clicks.<br/><strong>Step-2:</strong> Once your booking is confirmed, our dedicated team will promptly arrive at your doorstep to pick up your laundry. You can trust us to handle your garments with care and attention to detail. <br/><strong>Step-3: </strong>We understand the importance of timely delivery, which is why we guarantee that your freshly laundered clothes will be delivered back to you within 24 hours. <br/><br/>Our commitment to punctuality ensures that you never have to worry about missing your favorite outfit or being without your essentials. Experience the seamless process of our laundry service, from online booking to efficient pickup and delivery, allowing you to enjoy clean and fresh clothes hassle-free.</p>
                    
                </div>
                
            </div>
        </div>
    </div>
</section>
     );
}
 
export default ProcessBlock;